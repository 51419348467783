<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-form>
                <van-field
                    v-model="password.old_password"
                    type="password"
                    name="old_password"
                    label="旧密码"
                    placeholder="请输入旧密码"
                    :rules="[{ required: true, message: '请输入旧密码' }]"
                />
                <van-field
                    v-model="password.new_password"
                    type="password"
                    name="new_password"
                    label="新密码"
                    placeholder="请输入新密码"
                    :rules="[{ required: true, message: '请输入新密码' }]"
                />
                <van-field
                    v-model="password.con_password"
                    type="password"
                    name="con_password"
                    label="新密码"
                    placeholder="请再次输入新密码"
                    :rules="[{ required: true, message: '请再次输入新密码' }]"
                />
                <div class="sub_button">
                    <van-button round block type="primary" @click="submit">确 认</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { edit_password_request } from '@/network/user'

export default {
    name:'UserPassWordComponent',
    data(){
        return {
            password: {
                old_password: '',
                new_password: '',
                con_password: ''
            },
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '修改密码',
        }
    },
    computed:{},
    methods:{
        submit() {
            if (this.password.new_password !== this.password.con_password) {
                this.$toast.fail('两次新密码不一致')
                return
            }

            this.$store.commit('true_loading')
            edit_password_request(this.password)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.$router.go(-1);//返回上一层
                    } else {
                        this.$toast.fail(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        NavBarComponent,
        LoadingComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>